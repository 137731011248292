// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-pagination {
  clear: both;
  position: relative;
  text-align: center;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  display: block;
}

.btn-pagination > *, .btn-pagination .btn-group {
  display: inline-block;
  float: none;
}

.btn-pagination .btn {
  outline-style: none;
}

.page-arrow {
  margin-left: 0.34rem;
  margin-right: 0.34rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/pagination/pagination.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;AACvB","sourcesContent":[".btn-pagination {\n  clear: both;\n  position: relative;\n  text-align: center;\n  margin-bottom: 0.5rem;\n  margin-top: 0.5rem;\n  display: block;\n}\n\n.btn-pagination > *, .btn-pagination .btn-group {\n  display: inline-block;\n  float: none;\n}\n\n.btn-pagination .btn {\n  outline-style: none;\n}\n\n.page-arrow {\n  margin-left: 0.34rem;\n  margin-right: 0.34rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
