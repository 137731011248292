// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-holder
{
  position:       relative;
  display:        inline-block;
  vertical-align: middle;
  max-width:      14%;
}

.name-holder
{
  max-width: 85%;
  display:   inline-flex;
  overflow:  hidden;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/location-select/tag.css"],"names":[],"mappings":"AAAA;;EAEE,wBAAwB;EACxB,4BAA4B;EAC5B,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;;EAEE,cAAc;EACd,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":[".badge-holder\n{\n  position:       relative;\n  display:        inline-block;\n  vertical-align: middle;\n  max-width:      14%;\n}\n\n.name-holder\n{\n  max-width: 85%;\n  display:   inline-flex;\n  overflow:  hidden;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
