// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circled {
  background-color: rgb(127, 195, 68);
  color: #fff;
  font-weight: bold;
}

.cb {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/sl-distributor-cb/sl-distributor-cb.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".circled {\n  background-color: rgb(127, 195, 68);\n  color: #fff;\n  font-weight: bold;\n}\n\n.cb {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
