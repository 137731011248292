// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-holder {
  position: relative;
  display: inline-block;
  width: 3.3em;
  margin-left: -.7em;
  margin-right: .2em;
}

.badge-holder .badge {
  width: 100%;
}

.dropdown-menu {
  overflow: hidden;
}

.member-icon-holder {
  min-width: 1.5em;
  min-height: 1rem;
  display: inline-block;
}

.member-icon-holder > .material-icons {
  color: rgb(31, 169, 131);
  font-size: 1em;
  left: -.5em;
  top: .2em;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/header-search-box/tag.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,cAAc;EACd,WAAW;EACX,SAAS;AACX","sourcesContent":[".badge-holder {\n  position: relative;\n  display: inline-block;\n  width: 3.3em;\n  margin-left: -.7em;\n  margin-right: .2em;\n}\n\n.badge-holder .badge {\n  width: 100%;\n}\n\n.dropdown-menu {\n  overflow: hidden;\n}\n\n.member-icon-holder {\n  min-width: 1.5em;\n  min-height: 1rem;\n  display: inline-block;\n}\n\n.member-icon-holder > .material-icons {\n  color: rgb(31, 169, 131);\n  font-size: 1em;\n  left: -.5em;\n  top: .2em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
