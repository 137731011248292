import { Component, Input, Output, EventEmitter, } from "@angular/core";
import { Notify } from "../../services/Notify";
import { CostStudy } from "../../services/CostStudy";
import { Distributors } from "../../services/Distributors";
import { IDistributor } from "../../interfaces/IDistributor";
import { ICot } from "../../interfaces/ICot";
import { Cots } from "../../services/Cots";
import { Observable } from "rxjs";
import { ICsColor, ICSUploadMeta } from "../../interfaces/ICostStudy";
import { ICsUploadType } from "../../interfaces/ICostStudy";
import { User } from "../../services/User";
import { IUser } from "../../interfaces/IUser";
import { Config } from "../../services/Config";

@Component({
  templateUrl: "cs_meta.html",
  selector: "cs-meta-card"
})
export class CsMetaCardCmp {
  @Input() id: number;
  @Output() updated = new EventEmitter();
  @Input() setRep: Observable<any>;
  @Input() setLoc: Observable<any>;
  public meta: ICSUploadMeta = { type_id: 1 };
  public saving = false;
  public loading = false;
  public distributorsList: IDistributor[] = [];
  public cotsList: ICot[] = [];
  public typesList: ICsUploadType[];
  public analystUser: IUser;
  public isRepHasManager = true;
  public colors: ICsColor[] = [];
  public colorsMap: {} = {};
  private setRepSubscribed = false;
  private setLocSubscribed = false;

  constructor(public config: Config, private csSrv: CostStudy, private distributors: Distributors, private cots: Cots, private userSrv: User) {
    this.distributors.loadToList(this.distributorsList);
    this.cots.loadToList(this.cotsList);
    this.csSrv.getCsUploadTypes().subscribe((l) => { this.typesList = l; }, () => { });
  }

  ngOnInit() {
    this.load();
    if (this.setRep && !this.setRepSubscribed) {
      this.setRep.subscribe((rep) => {
        if (rep && rep.id) {
          this.meta.rep_user_id = rep.id;
        } else {
          this.meta.rep_user_id = null;
        }
      });
      this.setRepSubscribed = true;
    }
    if (this.setLoc && !this.setLocSubscribed) {
      this.setLoc.subscribe((loc) => {
        if (loc && loc.id) {
          this.meta.location_id = loc.id;
        } else {
          this.meta.location_id = null;
        }
      });
      this.setLocSubscribed = true;
    }
    this.csSrv.getCsColors().subscribe((c) => {
      if (c && c.length > 0) {
        this.colors = c;
        this.colorsMap = { 'none': { 'bg_color_hex': 'fff', 'text_color_hex': '000', 'label': 'None' } };
        c.forEach((color) => {
          this.colorsMap[color.code] = color;
        });
      }
    }, () => { });
  }

  ngOnChanges() {
    this.load();
  }

  load() {
    if (this.loading) {
      return;
    }
    if (this.id) {
      this.loading = true;
      this.csSrv.getCsUploadInfo(this.id).subscribe((i) => {
        this.meta = {
          id: this.id,
          ticket_number: i.ticket_number,
          rep_user_id: i.rep_id,
          analyst_user_id: i.analyst_user_id,
          distributor_id: i.distributor_id,
          class_of_trade_id: i.class_of_trade_id,
          type_id: i.type_id,
          notes: i.notes,
          received_date: i.received_date,
          date_ready: i.date_ready,
          date_to_imp_review: i.date_to_imp_review,
          returned_date: i.returned_date,
          is_loc_suggested: i.is_loc_suggested,
          is_loc_submitted: i.is_loc_submitted,
          is_tracking: i.is_tracking,
          location_id: i.facility_id,
          cst_color_code: i.cst_color_code,
          estimated_rows_count: i.estimated_rows_count,
          distributor_of_data_id: i.distributor_of_data_id,
          distributor_of_request_id: i.distributor_of_request_id,
          closed: i.closed,
        };
        this.loading = false;
      }, (err) => {
        this.loading = false;
        let n = new Notify();
        n.httpErr(err);
      });
    }
  }

  save() {
    if (this.saving) {
      return;
    }
    if (!this.meta) {
      return;
    }
    this.saving = true;
    let n = new Notify();
    this.csSrv.saveUploadMeta(this.meta).subscribe((idStr) => {
      let id = parseInt(idStr);
      if (!this.meta.id && id && id > 0) {
        this.meta.id = id;
        this.id = id;
      }
      this.saving = false;
      n.success('Updated');
      this.updated.emit(this.meta);
    }, (err) => {
      this.saving = false;
      n.httpErr(err);
    });
  }

  setSelectedAnalyst(analyst: IUser) {
    if (analyst && analyst.id) {
      this.analystUser = analyst;
      this.meta.analyst_user_id = analyst.id;
    } else {
      this.analystUser = null;
      this.meta.analyst_user_id = null;
    }
  }

  formatDate(date: Date | string): string {
    return new Date(date).toISOString().split('T')[0]; // Returns 'YYYY-MM-DD'
  }

}
