// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.narrow-state-column
{
  width: 6.5%
}

.wide-cm-name
{
  width: 10%
}
`, "",{"version":3,"sources":["webpack://./src/app/components/locations/locations.css"],"names":[],"mappings":"AAAA;;EAEE;AACF;;AAEA;;EAEE;AACF","sourcesContent":[".narrow-state-column\n{\n  width: 6.5%\n}\n\n.wide-cm-name\n{\n  width: 10%\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
